import create from 'zustand';
import api from '../services/apiService';

const useAuthStore = create((set) => ({
  isLoggedIn: false,
  jwtToken: null,
  googleIdToken: null,
  loading: true, // 新增 loading 狀態

  setLoggedIn: (jwtToken, googleIdToken) => {
    localStorage.setItem('jwtToken', jwtToken);
    localStorage.setItem('google_id_token', googleIdToken);
    set({ isLoggedIn: true, jwtToken, googleIdToken, loading: false });
  },

  setLoggedOut: () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('google_id_token');
    set({ isLoggedIn: false, jwtToken: null, googleIdToken: null, loading: false });
  },

  checkAuth: async () => {
    const token = localStorage.getItem('jwtToken');
    const id_token = localStorage.getItem('google_id_token');

    if (token) {
      try {
        await api.get('/api/users/google_verify', {
          headers: { Authorization: `Bearer ${token}` },
        });
        set({ isLoggedIn: true, jwtToken: token, googleIdToken: id_token, loading: false });
      } catch (error) {
        console.error('JWT token verification failed:', error);
        await useAuthStore.getState().handleTokenRefresh(id_token);
      }
    } else {
      await useAuthStore.getState().handleTokenRefresh(id_token);
    }
  },

  handleTokenRefresh: async (id_token) => {
    if (id_token) {
      try {
        const response = await api.post('/api/users/refresh_token', { id_token });
        const { jwtToken } = response.data;
        useAuthStore.getState().setLoggedIn(jwtToken, id_token);
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        useAuthStore.getState().setLoggedOut();
      }
    } else {
      useAuthStore.getState().setLoggedOut();
    }
  },

  loginWithGoogle: async (googleToken) => {
    try {
      const response = await api.post('/api/users/google_login', { code: googleToken });
      const { jwtToken, id_token } = response.data;
      useAuthStore.getState().setLoggedIn(jwtToken, id_token);
    } catch (error) {
      console.error('Google login failed:', error);
      useAuthStore.getState().setLoggedOut();
    }
  },
}));

export default useAuthStore;
