import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './styles/main.css';
import './i18n'; // 引入 i18n 設置

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
console.log('Google Client ID:', googleClientId);

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
