import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import { Button, Checkbox, FormControlLabel, Pagination, Box, CircularProgress } from '@mui/material';
import languageStore from '../../stores/languageStore';

const Step3_aiCreate = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { language, initialized } = languageStore.getState();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(
    JSON.parse(localStorage.getItem('selectedCategory')) || null
  );
  const [titles, setTitles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(
    JSON.parse(localStorage.getItem('selectedTitle')) || null
  );
  const [currentCategoryPage, setCurrentCategoryPage] = useState(1);
  const [currentTitlePage, setCurrentTitlePage] = useState(1);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingTitles, setLoadingTitles] = useState(false);
  const [fetchingCategories, setFetchingCategories] = useState(false);
  const [fetchingTitles, setFetchingTitles] = useState(false);
  const itemsPerPage = 8;
  const titlesPerPage = 8;

  const clearContent = () => {
    setCategories([]);
    setTitles([]);
    setSelectedCategory(null);
    setSelectedTitle(null);
    localStorage.removeItem('selectedCategory');
    localStorage.removeItem('selectedTitle');
  };

  useEffect(() => {
    if (initialized) {
      clearContent();
    }
    setLoadingCategories(true);
    fetchCategories([], true);
  }, [language, initialized]);

  const fetchCategories = async (existingCategoryIds = [], useFirstCategory = false) => {
    setFetchingCategories(true);
    try {
      const basicInfo = JSON.parse(localStorage.getItem('basicInfo')) || {};
      const { userName, englishName, age } = basicInfo;
      const response = await api.get('/api/stories/getcategory', {
        params: {
          existingCategoryIds: existingCategoryIds.join(','),
          userName,
          englishName,
          age,
        },
      });
      const newCategories = response.data;

      const combinedCategories = [...categories, ...newCategories].reduce((acc, current) => {
        if (!acc.find(category => category.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      setCategories(combinedCategories);
      setLoadingCategories(false);

      if (selectedCategory) {
        const storedCategory = combinedCategories.find(
          (category) => category.id === selectedCategory.id
        );
        if (storedCategory) {
          handleCategoryClick(storedCategory);
        } else if (useFirstCategory) {
          handleCategoryClick(combinedCategories[0]);
        }
      } else if (useFirstCategory && combinedCategories.length > 0) {
        handleCategoryClick(combinedCategories[0]);
      }
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    } finally {
      setFetchingCategories(false);
    }
  };

  const fetchTitles = async (categoryId) => {
    setFetchingTitles(true);
    setLoadingTitles(true);
    const existingTitleIds = titles.map(title => title.id);

    try {
      const basicInfo = JSON.parse(localStorage.getItem('basicInfo')) || {};
      const { userName, englishName, age } = basicInfo;

      const response = await api.get('/api/stories/gettitles', {
        params: {
          categoryId,
          existingTitleIds: existingTitleIds.join(','),
          userName,
          englishName,
          age,
        },
      });

      const newTitles = response.data.map((title) => ({ ...title, categoryId: title.category_id }));
      const combinedTitles = [...titles, ...newTitles].reduce((acc, current) => {
        if (!acc.find(title => title.id === current.id)) {
          acc.push(current);
        }
        return acc;
      }, []);

      setTitles(combinedTitles);

      if (selectedTitle && selectedTitle.categoryId === categoryId) {
        const storedTitle = combinedTitles.find((title) => title.id === selectedTitle.id);
        if (!storedTitle) {
          setSelectedTitle(null);
          localStorage.removeItem('selectedTitle');
        }
      }
    } catch (error) {
      console.error('Failed to fetch titles:', error);
    } finally {
      setFetchingTitles(false);
      setLoadingTitles(false);
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    localStorage.setItem('selectedCategory', JSON.stringify(category));
    setCurrentTitlePage(1);
  
    // 檢查是否已經有該分類的標題資料，若有則不再從 API 抓取
    const existingTitles = titles.filter((title) => title.categoryId === category.id);
    if (existingTitles.length > 0) {
      setLoadingTitles(false);
      setFetchingTitles(false);
    } else {
      fetchTitles(category.id);
    }
  };

  const handleTitleSelect = (title) => {
    const isAlreadySelected = selectedTitle && selectedTitle.id === title.id;

    if (isAlreadySelected) {
      setSelectedTitle(null);
      localStorage.removeItem('selectedTitle');
    } else {
      setSelectedTitle(title);
      localStorage.setItem('selectedTitle', JSON.stringify(title));
    }
  };

  const refreshCategories = () => {
    const existingCategoryIds = categories.map(category => category.id);
    fetchCategories(existingCategoryIds);
  };

  const refreshTitles = () => {
    if (selectedCategory) {
      fetchTitles(selectedCategory.id);
    }
  };

  const goToNextStep = () => {
    navigate('/create-story/step4-storyList');
  };

  const goBack = () => {
    navigate('/create-story/step2-choiceType');
  };

  const displayedTitles = titles.filter(title => title.categoryId === selectedCategory?.id).slice(
    (currentTitlePage - 1) * titlesPerPage,
    currentTitlePage * titlesPerPage
  );

  const totalPages = Math.ceil(titles.filter(title => title.categoryId === selectedCategory?.id).length / titlesPerPage);

  return (
    <div className="container mx-auto p-6">
    <div className="flex items-start justify-center mb-8 relative">
      <div className="flex items-start space-x-8">
        {/* 上一步按鈕 */}
        <div className="fixed bottom-8 left-[20%] z-50">
          <button
            className="bg-gray-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105"
            onClick={goBack}
          >
            {t('common.previous')}
          </button>
        </div>

        {/* 中間導航 */}
        <StepNavigation currentStep={3} />

        {/* 下一步按鈕 */}
        <div className="fixed bottom-8 right-[20%] z-50">
          <button
            className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105"
            onClick={goToNextStep}
          >
            {t('common.next')}
          </button>
        </div>
      </div>
    </div>

      <div className="text-center mb-12">
        <h2 className="text-yellow-500 text-3xl font-bold mb-4">{t('step3_aiCreate.title')}</h2>
      </div>

      {loadingCategories ? (
        <div className="flex justify-center items-center">
          <span className="text-white text-lg">{t('step3_aiCreate.loadingCategories')}</span>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-2 mb-8">
            {categories.slice(
              (currentCategoryPage - 1) * itemsPerPage,
              currentCategoryPage * itemsPerPage
            ).map((category, index) => (
              <button
                key={index}
                onClick={() => handleCategoryClick(category)}
                className={`py-2 px-4 rounded-full text-lg ${category.id == selectedCategory?.id ? 'bg-teal-500 text-white' : 'bg-gray-700 text-white'}`}>
                {category.category}
              </button>
            ))}
          </div>

          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Pagination
              count={Math.ceil(categories.length / itemsPerPage)}
              page={currentCategoryPage}
              onChange={(event, value) => setCurrentCategoryPage(value)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#ffffff',
                  borderColor: '#D3D3D3',
                },
                '& .Mui-selected': {
                  backgroundColor: '#00bcd4',
                  color: '#ffffff',
                  borderColor: '#00bcd4',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#424242',
                },
              }}
            />
            <Button
              onClick={refreshCategories}
              variant="outlined"
              sx={{
                mx: 2,
                color: '#ffffff',
                borderColor: '#00bcd4',
                '&:hover': {
                  backgroundColor: '#00bcd4',
                  borderColor: '#00bcd4',
                },
              }}
            >
              {t('step3_aiCreate.refreshCategories')}
              {fetchingCategories && <CircularProgress size={20} sx={{ ml: 2, color: '#ffffff' }} />}
            </Button>
          </Box>
        </>
      )}

      {loadingTitles && displayedTitles.length === 0 ? (
        <div className="flex justify-center items-center">
          <span className="text-white text-lg">{t('step3_aiCreate.loadingTitles')}</span>
        </div>
      ) : displayedTitles.length > 0 ? (
        <>
          <div className="text-left mb-8 text-gray-300 bg-gray-800 p-4 rounded-lg">
            {displayedTitles.map((title, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={
                      selectedTitle &&
                      selectedTitle.title === title.title &&
                      selectedTitle.categoryId == title.categoryId
                    }
                    onChange={() => handleTitleSelect(title)}
                    sx={{
                      color: 'white',
                      '&.Mui-checked': {
                        color: '#00bcd4',
                      },
                    }}
                  />
                }
                label={`${title.title}：${title.description}`}
                sx={{ marginBottom: '16px', color: 'white' }}
              />
            ))}
          </div>

          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Pagination
              count={totalPages}
              page={currentTitlePage}
              onChange={(event, value) => setCurrentTitlePage(value)}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#ffffff',
                  borderColor: '#D3D3D3',
                },
                '& .Mui-selected': {
                  backgroundColor: '#00bcd4',
                  color: '#ffffff',
                  borderColor: '#D3D3D3',
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#424242',
                },
              }}
            />
            <Button onClick={refreshTitles} variant="outlined" sx={{ mx: 2 }}>
              {t('step3_aiCreate.refreshTitles')}
              {fetchingTitles && <CircularProgress size={20} sx={{ ml: 2, color: '#ffffff' }} />}
            </Button>
          </Box>
        </>
      ) : (
        <div className="flex justify-center items-center">
          <span className="text-white text-lg">{t('step3_aiCreate.noTitles')}</span>
        </div>
      )}
    </div>
  );
};

export default Step3_aiCreate;
