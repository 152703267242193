import React from 'react';
import HeroSection from './HeroSection';
import BookShowcase from './BookShowcase';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleStartStoryCreation = () => {
    navigate('/create-story');
  };

  return (
    <div className="container mx-auto px-4 py-20 text-gray-300">
      <HeroSection />

      <div className="mb-12">
        <div className="flex flex-col md:flex-row items-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
            <img src="/assets/400-9.jpg" alt={t('home.adventure.alt')} className="w-64 h-64 object-cover rounded" />
          </div>
          <div className="flex flex-col items-start md:ml-8 mt-4 md:mt-0 text-gray-100">
            <h2 className="text-3xl font-bold">{t('home.adventure.title')}</h2>
            <p className="text-md text-gray-400 mt-2 mb-4">{t('home.adventure.description')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.adventure.price')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.adventure.summary')}</p>
            <ul className="list-disc list-inside text-md text-gray-400 mt-2">
              <li>{t('home.adventure.story1')}</li>
              <li>{t('home.adventure.story2')}</li>
              <li>{t('home.adventure.story3')}</li>
              <li>{t('home.adventure.story4')}</li>
              <li>{t('home.adventure.story5')}</li>
            </ul>
            <button 
              className="bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded-lg mt-4"
              onClick={handleStartStoryCreation}
            >
              {t('home.startCreatingStory')}
            </button>
          </div>
        </div>
      </div>

      <div className="mb-12">
        <div className="flex flex-col md:flex-row items-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
            <img src="/assets/400-10.jpg" alt={t('home.education.alt')} className="w-64 h-64 object-cover rounded" />
          </div>
          <div className="flex flex-col items-start md:ml-8 mt-4 md:mt-0 text-gray-100">
            <h2 className="text-3xl font-bold">{t('home.education.title')}</h2>
            <p className="text-md text-gray-400 mt-2 mb-4">{t('home.education.description')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.education.price')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.education.summary')}</p>
            <ul className="list-disc list-inside text-md text-gray-400 mt-2">
              <li>{t('home.education.story1')}</li>
              <li>{t('home.education.story2')}</li>
              <li>{t('home.education.story3')}</li>
              <li>{t('home.education.story4')}</li>
              <li>{t('home.education.story5')}</li>
            </ul>
            <button 
              className="bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded-lg mt-4"
              onClick={handleStartStoryCreation}
            >
              {t('home.startCreatingStory')}
            </button>
          </div>
        </div>
      </div>

      <BookShowcase />
    </div>
  );
};

export default Home;
