export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on some images
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width, height, rotation) {
const rotRad = getRadianAngle(rotation);
return {
  width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
  height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
};
}

// 更新的 getCroppedImg 函數，帶有白色背景
export default async function getCroppedImg(imageSrc, pixelCrop) {
const image = await createImage(imageSrc);
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

canvas.width = pixelCrop.width;
canvas.height = pixelCrop.height;

// 在繪製圖片之前，將 canvas 背景設置為白色
ctx.fillStyle = 'white'; // 設定背景顏色為白色
ctx.fillRect(0, 0, canvas.width, canvas.height); // 填充整個 canvas 作為背景

// 繪製裁剪後的圖片到 canvas 上
ctx.drawImage(
  image,
  pixelCrop.x,
  pixelCrop.y,
  pixelCrop.width,
  pixelCrop.height,
  0,
  0,
  pixelCrop.width,
  pixelCrop.height
);

return new Promise((resolve, reject) => {
  canvas.toBlob((blob) => {
    if (blob) {
      resolve(blob); // 返回裁剪後的 Blob
    } else {
      reject(new Error('裁剪失敗'));
    }
  }, 'image/jpeg'); // 可以指定圖片的格式，例如 'image/jpeg'
});
}
