import axios from 'axios';
import useAuthStore from '../stores/authStore';
import languageStore from '../stores/languageStore'; // 引入語言設置的 Store

// 設定 Axios 的基本配置
console.log('Base URL:', process.env.REACT_APP_API_BASE_URL);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // 使用你的後端 API 伺服器地址
  headers: {
    'Content-Type': 'application/json',
  },
});

// 增加 JWT token 和 lang 到每個請求的頭部或查詢參數
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('jwtToken');
  const lang = languageStore.getState().language; // 從語言 Store 中獲取語言設置

  // 設置 Authorization 頭部
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  // 如果是 GET 請求，將 lang 添加到查詢參數
  config.params = {
    ...config.params,
    lang, // 附加語言參數
  };
  if (config.method != 'get') {
    // 非 GET 請求時，將 lang 添加到請求體，合併 data
    if (config.data instanceof FormData) {
      // 如果是 FormData，則要單獨處理，因為 FormData 不支持展開語法
      config.data.append('lang', lang);
    } else {
      // 如果不是 FormData，則合併數據
      config.data = {
        ...config.data,
        lang, // 附加語言參數
      };
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

// 處理過期 token
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const errorMessage = error.response?.data?.error || error.message;

    // 檢查是否是 token 過期的錯誤
    if (error.response?.status === 401 && errorMessage === 'jwt expired') {
      try {
        // 嘗試刷新 token
        const id_token = localStorage.getItem('google_id_token');
        if (id_token) {
          await useAuthStore.getState().handleTokenRefresh(id_token);

          // 成功刷新 token，重新發送原請求
          const newToken = localStorage.getItem('jwtToken');
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // 重新發送失敗的請求
          return api(originalRequest);
        }
      } catch (refreshError) {
        // 刷新 token 失敗，登出用戶
        useAuthStore.getState().setLoggedOut();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// 新增一個方法來處理圖片請求，並將 token 和 lang 添加到請求頭部
api.showImage = async (url) => {
  try {
    const response = await api.get(url, {
      responseType: 'blob', // 確保返回的是二進制數據
    });
    return URL.createObjectURL(new Blob([response.data]));
  } catch (error) {
    console.error('Failed to fetch image:', error);
    throw error; // 向上傳遞錯誤以便進一步處理
  }
};

export default api;
