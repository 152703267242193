import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HTMLFlipBook from 'react-pageflip';
import { useTranslation } from 'react-i18next';

const HeroSection = () => {
  const { t } = useTranslation();
  const bookRef = useRef();

  const images = [
    "/assets/800x400-1-1.jpg",
    "/assets/800x400-1-2.jpg",
    "/assets/800x400-2-1.jpg",
    "/assets/800x400-2-2.jpg",
    "/assets/800x400-3-1.jpg",
    "/assets/800x400-3-2.jpg",
    "/assets/800x400-4-1.jpg",
    "/assets/800x400-4-2.jpg",
    "/assets/800x400-5-1.jpg",
    "/assets/800x400-5-2.jpg",
    "/assets/800x400-6-1.jpg",
    "/assets/800x400-6-2.jpg",
    "/assets/800x400-7-1.jpg",
    "/assets/800x400-7-2.jpg",
    "/assets/800x400-8-1.jpg",
    "/assets/800x400-8-2.jpg"
  ];

  const navigate = useNavigate();

  const handleStartStoryCreation = () => {
    navigate('/create-story');
  };

  return (
    <div className="flex flex-col items-center md:flex-row md:items-start mb-12">
      <HTMLFlipBook
        width={400}
        height={400}
        size="stretch"
        minWidth={400}
        maxWidth={400}
        minHeight={400}
        maxHeight={400}
        drawShadow={true}
        flippingTime={1000}
        usePortrait={true}
        startZIndex={0}
        autoSize={true}
        maxShadowOpacity={0.5}
        mobileScrollSupport={true}
        ref={bookRef}
      >
        {images.map((imageUrl, index) => (
          <div key={index} className="demoPage">
            <img src={imageUrl} alt={`Page ${index + 1}`} className="w-full h-auto" />
          </div>
        ))}
      </HTMLFlipBook>

      <div className="flex flex-col items-center md:ml-8 mt-4 md:mt-0">
        <img
          src="/assets/800x400-1-1.jpg"
          alt={t('hero.thumbnailAlt')}
          className="rounded-lg shadow-lg mb-4"
          style={{
            width: '200px',
            height: '200px',
            objectFit: 'cover'
          }}
        />
        <button 
          className="bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded-lg"
          onClick={handleStartStoryCreation}
        >
          {t('hero.startCreatingStory')}
        </button>
      </div>
    </div>
  );
};

export default HeroSection;
