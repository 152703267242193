import React, { useState } from 'react';
import { Box, Button, Typography, TextField, Modal } from '@mui/material';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import useBalanceStore from '../../stores/balanceStore';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'; // 引入翻譯

function PaymentModal({ isOpen, toggle }) {
    const { t } = useTranslation(); // 使用 useTranslation 來獲取翻譯
    const [amount, setAmount] = useState('5.00');
    const createPayPalOrder = useBalanceStore(state => state.createPayPalOrder);
    const approvePayPalOrder = useBalanceStore(state => state.approvePayPalOrder);

    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture"
    };

    const createOrder = async (data, actions) => {
        try {
            const orderId = await actions.order.create({
                purchase_units: [{
                    amount: {
                        currency_code: "USD",
                        value: amount
                    }
                }],
                "payment_source": {
                    "paypal": {
                        "experience_context": {
                            shipping_preference: "NO_SHIPPING",
                            payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
                            user_action: "PAY_NOW",
                            brand_name: "EXAMPLE INC",
                        }
                    }
                }
            });

            await createPayPalOrder(amount, orderId);
            return orderId;
        } catch (error) {
            toggle();
            Swal.fire({
                icon: 'error',
                title: t('balanceList.error'),
                text: t('balanceList.createOrderFailed'),
            });
        }
    };

    const onApprove = async (data) => {
        try {
            const orderId = data.orderID;
            await approvePayPalOrder(orderId);
            Swal.fire({
                icon: 'success',
                title: t('balanceList.success'),
                text: t('balanceList.transactionCompleted'),
            });
            toggle();
        } catch (error) {
            toggle();
            Swal.fire({
                icon: 'error',
                title: t('balanceList.error'),
                text: t('balanceList.approveFailed'),
            });
        }
    };

    return (
        <Modal open={isOpen} onClose={toggle}>
            <Box sx={{
                bgcolor: 'white',
                p: 4,
                borderRadius: 2,
                maxWidth: 400,
                margin: 'auto',
                mt: 10,
                maxHeight: '90vh',
                overflowY: 'auto'
            }}>
                <Typography variant="h6" mb={2} sx={{ color: '#000' }}>
                    {t('balanceList.topUp')}
                </Typography>
                <TextField
                    type="number"
                    label={t('balanceList.amount')}
                    variant="outlined"
                    value={amount}
                    onChange={e => setAmount(parseFloat(e.target.value))}
                    onClick={e => e.target.select()}
                    fullWidth
                />
                <Box mt={3}>
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons
                            key={amount}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            onError={(error) => {
                                toggle();
                                Swal.fire({
                                    icon: 'error',
                                    title: t('balanceList.error'),
                                    text: t('balanceList.paymentFailed'),
                                });
                            }}
                            style={{
                                shape: 'pill',
                                label: 'pay',
                                height: 40
                            }}
                        />
                    </PayPalScriptProvider>
                </Box>
                <Button onClick={toggle} sx={{ mt: 2 }} variant="contained" color="secondary">
                    {t('balanceList.cancel')}
                </Button>
            </Box>
        </Modal>
    );
}

export default PaymentModal;
