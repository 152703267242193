import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuthStore from '../stores/authStore';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n'; // 引入 i18n
import useLanguageStore from '../stores/languageStore'; // 引入語言 store

const Navbar = () => {
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const setLoggedOut = useAuthStore((state) => state.setLoggedOut);
  const navigate = useNavigate();

  const { t } = useTranslation(); // 使用 useTranslation 來取得翻譯方法
  const language = useLanguageStore((state) => state.language); // 獲取當前語言
  const setLanguage = useLanguageStore((state) => state.setLanguage); // 設定語言方法

  const handleLogout = () => {
    setLoggedOut();
    navigate('/');
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage); // 通過 store 來設定語言
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 text-white shadow-lg z-50">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="text-2xl font-extrabold">Make Your Storybook</Link>
        <div className="flex space-x-8 text-lg font-semibold">
          <Link to="/" className="hover:underline">{t('navbar.home')}</Link>
          <Link to="/create-story" className="hover:underline">{t('navbar.createStory')}</Link>
          <Link to="/create-story/mystorylist" className="hover:underline">{t('navbar.myStory')}</Link>
          <Link to="/balance" className="hover:underline">{t('navbar.balance')}</Link>
          {isLoggedIn ? (
            <button 
              onClick={handleLogout} 
              className="hover:underline"
            >
              {t('navbar.logout')}
            </button>
          ) : (
            <button 
              onClick={handleLoginClick} 
              className="hover:underline"
            >
              {t('navbar.login')}
            </button>
          )}
        </div>
        {/* 語言切換下拉選單 */}
        <select
          value={language} // 使用 store 中的語言狀態
          onChange={handleLanguageChange}
          className="bg-white text-black rounded px-2 py-1 ml-4"
        >
          <option value="zh">中文</option>
          <option value="en">English</option>
        </select>
      </div>
    </nav>
  );
};

export default Navbar;
