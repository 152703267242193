import React, { useEffect, useState } from 'react';
import useBalanceStore from '../../stores/balanceStore';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Typography, Box, Pagination } from '@mui/material';
import PaymentModal from './PaymentModal';
import { useTranslation } from 'react-i18next'; // 使用 i18next

const BalanceList = () => {
  const { t } = useTranslation(); // 使用 useTranslation 來獲取翻譯
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dailyTransactions = useBalanceStore(state => state.dailyTransactions);
  const totalBalance = useBalanceStore(state => state.totalBalance);
  const getDailyTransactions = useBalanceStore(state => state.getDailyTransactions);
  const getTotalBalance = useBalanceStore(state => state.getTotalBalance);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getDailyTransactions(page).then(response => {
      setTotalPages(response.totalPages);
    });
    getTotalBalance();
  }, [getDailyTransactions, getTotalBalance, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  return (
    <Box className="container mx-auto p-6" sx={{ bgcolor: '#1c1c28', color: '#fff', borderRadius: 2, p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" component="h2" gutterBottom>
          {t('balanceList.dailyTransactions')}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" component="h3" sx={{ mr: 2 }}>
            {t('balanceList.availableBalance')}: <strong>${totalBalance.toFixed(2)}</strong>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={toggleModal}
            sx={{ bgcolor: '#ff6f61', '&:hover': { bgcolor: '#ff3f3f' } }}
          >
            {t('balanceList.topUp')}
          </Button>
        </Box>
      </Box>

      <Table sx={{ backgroundColor: '#2a2a3c', borderRadius: '8px' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: '#f0f0f0', fontWeight: 'bold' }}>{t('balanceList.transactionDescription')}</TableCell>
            <TableCell sx={{ color: '#f0f0f0', fontWeight: 'bold' }}>{t('balanceList.amount')}</TableCell>
            <TableCell sx={{ color: '#f0f0f0', fontWeight: 'bold' }}>{t('balanceList.date')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dailyTransactions.map((transaction, index) => (
            <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#343446' } }}>
              <TableCell sx={{ color: '#e0e0e0' }}>{transaction.description}</TableCell>
              <TableCell sx={{ color: transaction.amount < 0 ? '#ff7675' : '#55efc4' }}>
                {transaction.amount.toFixed(2)}
              </TableCell>
              <TableCell sx={{ color: '#e0e0e0' }}>{new Date(transaction.date).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        sx={{
          mt: 3,
          display: 'flex',
          justifyContent: 'center',
          "& .MuiPaginationItem-root": {
            color: "#fff",
            backgroundColor: "#343446",
            borderRadius: "50%",
          },
          "& .Mui-selected": {
            backgroundColor: "#ff6f61",
            color: "#fff",
          },
          "& .MuiPaginationItem-ellipsis": {
            color: "#fff",
          },
          "& .MuiPaginationItem-page:hover": {
            backgroundColor: "#ff3f3f",
          },
        }}
        color="primary"
      />

      <PaymentModal isOpen={isModalOpen} toggle={toggleModal} />
    </Box>
  );
};

export default BalanceList;
