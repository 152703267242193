import React from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import useAuthStore from '../../stores/authStore';
import { useTranslation } from 'react-i18next';

const GoogleLoginButton = ({ onLoginSuccess }) => {
  const { t } = useTranslation();
  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
  const loginWithGoogle = useAuthStore((state) => state.loginWithGoogle);
  const setLoggedOut = useAuthStore((state) => state.setLoggedOut);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      await loginWithGoogle(codeResponse.code);
      onLoginSuccess();
    },
    onError: () => console.log('Login Failed'),
    flow: 'auth-code',
  });

  const logout = () => {
    googleLogout();
    setLoggedOut();
  };

  return (
    <div>
      {isLoggedIn ? (
        <button onClick={logout} className="bg-red-500 text-white font-bold py-2 px-4 rounded">
          {t('login.logout')}
        </button>
      ) : (
        <button onClick={() => login()} className="bg-blue-500 text-white font-bold py-2 px-4 rounded" aria-label={t('login.googleSignIn')}>
          {t('login.googleSignIn')}
        </button>
      )}
    </div>
  );
};

export default GoogleLoginButton;
