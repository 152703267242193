import create from 'zustand';
import api from '../services/apiService'; // 使用 apiService

const useBalanceStore = create((set, get) => ({
  dailyTransactions: [],
  totalBalance: 0, // 存儲總餘額

  // 獲取每日交易
  getDailyTransactions: async (page = 1) => {
    try {
      const response = await api.get(`/api/balance/dailyTransactions?page=${page}`); // 傳遞頁數
      set({
        dailyTransactions: response.data.transactions // 設置交易數據
      });
      return {
        totalPages: response.data.totalPages, // 返回總頁數
      };
    } catch (error) {
      console.error('Error getting daily transactions:', error);
      throw new Error('無法獲取交易紀錄');
    }
  },
  
  // 獲取總餘額
  getTotalBalance: async () => {
    try {
      const response = await api.get('/api/balance/totalBalance');
      const balance = parseFloat(response.data.totalBalance);
      set({ totalBalance: balance });
    } catch (error) {
      console.error('Failed to fetch total balance:', error);
      throw new Error('無法獲取餘額');
    }
  },

  // 建立 PayPal 訂單
  createPayPalOrder: async (amount, orderId) => {
    try {
      const response = await api.post('/api/balance/paypal', { amount, orderId });
      return response.data;
    } catch (error) {
      console.error('Error creating PayPal order:', error);
      throw new Error('建立 PayPal 訂單失敗');
    }
  },

  // 批准 PayPal 訂單
  approvePayPalOrder: async (orderId) => {
    try {
      const response = await api.post('/api/balance/paypalApprove', { orderId });
      await get().getDailyTransactions(); // 更新交易列表
      await get().getTotalBalance(); // 更新餘額
      return response.data;
    } catch (error) {
      console.error('Error approving PayPal order:', error);
      throw new Error('批准 PayPal 訂單失敗');
    }
  },
}));

export default useBalanceStore;
