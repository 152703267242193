import React from 'react';
import { useTranslation } from 'react-i18next';

const BookShowcase = () => {
  const { t } = useTranslation();

  const books = [
    {
      imgSrc: "/assets/400-1.jpg",
      title: t('bookShowcase.timeAdventurerRyan'),
      description: t('bookShowcase.timeAdventurerRyanDescription')
    },
    {
      imgSrc: "/assets/400-2.jpg",
      title: t('bookShowcase.islaOceanSanctuary'),
      description: t('bookShowcase.islaOceanSanctuaryDescription')
    },
    {
      imgSrc: "/assets/400-3.jpg",
      title: t('bookShowcase.masaDinosaurAdventure'),
      description: t('bookShowcase.masaDinosaurAdventureDescription')
    },
    {
      imgSrc: "/assets/400-4.jpg",
      title: t('bookShowcase.edenLaboratoryJoy'),
      description: t('bookShowcase.edenLaboratoryJoyDescription')
    },
    {
      imgSrc: "/assets/400-5.jpg",
      title: t('bookShowcase.belleMagicalTreehouse'),
      description: t('bookShowcase.belleMagicalTreehouseDescription')
    },
    {
      imgSrc: "/assets/400-6.jpg",
      title: t('bookShowcase.danielFlyingFawn'),
      description: t('bookShowcase.danielFlyingFawnDescription')
    },
    {
      imgSrc: "/assets/400-7.jpg",
      title: t('bookShowcase.emilyMagicSeed'),
      description: t('bookShowcase.emilyMagicSeedDescription')
    },
    {
      imgSrc: "/assets/400-8.jpg",
      title: t('bookShowcase.ethanBalloonKingdom'),
      description: t('bookShowcase.ethanBalloonKingdomDescription')
    },
    {
      imgSrc: "/assets/400-9.jpg",
      title: t('bookShowcase.ryanMagicRainbow'),
      description: t('bookShowcase.ryanMagicRainbowDescription')
    }
  ];

  return (
    <div className="text-center mb-12">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        {books.map((book, index) => (
          <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform">
            <img src={book.imgSrc} alt={book.title} className="w-400 h-400 object-cover rounded mb-4" />
            <h2 className="text-2xl font-bold text-gray-100">{book.title}</h2>
            <p className="text-gray-400 mt-2">{book.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookShowcase;
