import React from 'react';
import { useTranslation } from 'react-i18next';

const StepNavigation = ({ currentStep }) => {
  const { t } = useTranslation();
  const steps = [
    t('steps.uploadImages'),
    t('steps.choiceType'),
    t('steps.createStory'),
    t('steps.storyList'),
    t('steps.picViewer'),
    t('steps.bookViewer'),
  ];

  return (
    <div className="flex justify-center mb-8">
      <div className="flex flex-wrap items-center space-x-2 md:space-x-4">
        {steps.map((label, index) => (
          <React.Fragment key={index}>
            <div className="flex flex-col items-center">
              <div
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  index + 1 === currentStep
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-600 text-gray-200'
                }`}
              >
                {index + 1}
              </div>
              <span
                className={`mt-2 text-xs md:text-sm ${
                  index + 1 === currentStep ? 'text-gray-300' : 'text-gray-300'
                }`}
              >
                {label}
              </span>
            </div>
            {index < steps.length - 1 && (
              <div
                className={`w-4 h-1 md:w-8 ${
                  index + 1 < currentStep ? 'bg-blue-500' : 'bg-gray-600'
                }`}
              ></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default StepNavigation;
