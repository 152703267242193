import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GoogleLoginButton from './GoogleLoginButton';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation 來處理語言切換

const LoginPage = ({ onLoginSuccess }) => {
  const { t } = useTranslation(); // 使用 t 函數來獲取翻譯
  const location = useLocation();
  const navigate = useNavigate();

  const handleLoginSuccess = () => {
    try {
      onLoginSuccess();
    } catch (error) {
      console.error("Error occurred in onLoginSuccess:", error);
    }

    const redirectTo = location.state?.from?.pathname || '/';
    navigate(redirectTo, { replace: true });
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-800">
      <div className="text-center">
        <h1 className="text-2xl text-white mb-6">{t('login.prompt')}</h1> {/* 使用 t 函數來取代硬編碼文字 */}
        <GoogleLoginButton onLoginSuccess={handleLoginSuccess} />
      </div>
    </div>
  );
};

export default LoginPage;
