import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../components/home/Home';
import Step1_uploadImage from '../components/createstory/Step1_uploadImage';
import Step2_choiceType from '../components/createstory/Step2_choiceType';
import Step3_selfCreate from '../components/createstory/Step3_selfCreate'; 
import Step3_aiCreate from '../components/createstory/Step3_aiCreate';  
import Step4_storyList from '../components/createstory/Step4_storyList'; 
import Step5_picView from '../components/createstory/Step5_picView'; 
import Step6_bookViewer from '../components/createstory/Step6_bookViewer'; 
import StoryListViewer from '../components/createstory/StoryListViewer';
import LoginPage from '../components/auth/LoginPage';
import ProtectedRoute from './ProtectedRoute';
import BalanceList from '../components/balance/BalanceList';  // 引入 BalanceList

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<LoginPage />} />

      {/* Protected Routes */}
      <Route 
        path="/create-story/*" 
        element={
          <ProtectedRoute>
            <Routes>
              <Route path="" element={<Step1_uploadImage />} />
              <Route path="step2-choiceType" element={<Step2_choiceType />} />
              <Route path="step3-selfCreate" element={<Step3_selfCreate />} />
              <Route path="step3-aiCreate" element={<Step3_aiCreate />} />
              <Route path="step4-storyList" element={<Step4_storyList />} />
              <Route path="step5-picView" element={<Step5_picView />} />
              <Route path="step6-bookViewer" element={<Step6_bookViewer />} />
              <Route path="mystorylist" element={<StoryListViewer />} />
            </Routes>
          </ProtectedRoute>
        }
      />

      {/* 新增的 BalanceList 路由 */}
      <Route 
        path="/balance" 
        element={
          <ProtectedRoute>
            <BalanceList />
          </ProtectedRoute>
        } 
      />
    </Routes>
  );
};

export default AppRoutes;
